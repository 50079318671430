<template>
    <b-card @click="fireClickEvent" no-body :bg-variant="isActive ? '' : 'transparent' " :class=" isActive ? 'is-picker' : 'is-picker with-border' ">

        <b-card-body style="padding: 24px;">
            <p style="margin-bottom: 6px;"> Расчетный баланс <b-link @click="changeCurrency">в {{ User.currency }}</b-link> </p>

            <div class="d-flex">
                <p style="font-size: 28px; font-weight: 600;">{{ currency_balance }} {{ User.getSymbol() }}</p>
            </div>

            <div style="margin-top: 6px;">
                <span class="text-muted font-weight-light">
                    Добыто чистыми за 24 часа
                </span>
                <p style="font-size: 22px; font-weight: 500; margin-top: 4px;">
                    {{ last_profit.formatMoney(User.currency === 'BTC' ? 5 : 2,' ', ' ') }} {{ ticker }}
                </p>
            </div>
            
        
            <div style="margin-top: 22px;">
                <p class="text-muted font-weight-light" style="margin-bottom: 4px;">
                    Кол. контрактов
                </p>
                <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-primary"
                        rounded
                      >
                        <feather-icon
                          icon="CloudIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ user.cloud_count }}
                        </h5>
                        <small class="text-muted">Облако</small>
                      </div>
                </div>

                <div class="d-flex align-items-center mr-2 mt-1">
                      <b-avatar
                        variant="light-danger"
                        rounded
                      >
                        <feather-icon
                          icon="CpuIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ user.miners_count }}
                        </h5>
                        <small class="text-muted">Майнеры</small>
                      </div>
                </div>
            </div>
        
            <div style="margin-top: 22px;">
                <span class="text-muted font-weight-light">
                    Суммарная мощность
                </span>
                <div class="d-flex" style="margin-top: 4px;">

                    <div class="energy-icon mt-auto mb-auto" style="margin-right: 6px;"></div>

                    <p class="mb-0">
                        <span  style="font-size: 24px; font-weight: 500;">{{ total_ths }}</span> 
                        <span style="font-size: 18px;"> TH/s</span>
                    </p>
                </div>
            </div>
        
        </b-card-body>
    
        

    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    
    export default {

        data() {
            return {
                User,
            }
        },
        props: {
            user: Object,
            isActive: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            fireClickEvent( args ) {
                this.$emit( "click", args );
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
        },
        
        computed: {
            
            ticker() {
               return User.getSymbol();
            },
            
            total_ths() {
               if( this.$route.name === "user-contracts-list") {
                    return this.user.stats.cloud.total_ths.formatMoney(2," ", " ");       
               }
               
               if( this.$route.name === "user-miners-list") {
                   return this.user.stats.miners.total_ths.formatMoney(2," ", " ");       
               }
                
               return (this.user.stats.miners.total_ths + this.user.stats.cloud.total_ths).formatMoney(2," ", " ");
               
            }, 

           
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>